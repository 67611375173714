<template>
  <div>

    <b-modal
      id="modal-expense-category"
      size="xl"
      title="Pilih Kategori Pengeluaran Apotek"
      hide-footer
    >
      <b-tabs content-class="mt-3">
        <b-tab
          title="Daftar Kategori Pengeluaran Apotek"
          active
        >
          <Table
            purpose="modal"
            @chooseCategory="chosenCategory"
          />
        </b-tab>
        <b-tab title="Tambah Baru">
          <Form
            purpose="modal"
            :form="form"
            editTarget=""
            route="pharmacy-expense-categories"
            @formOnSubmit="submitedCategory"
          />
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>

import Form from '@/component/pharmacy/pharmacy-expense-categories/Form.vue'
import Table from '@/component/pharmacy/pharmacy-expense-categories/Table.vue'

export default {

  components: {
    Table,
    Form
  },

  data() {
    return {
      // Form
      form: {
        name: '',
        description: ''
      }
    }
  },

  methods: {

    chosenCategory(value) {
      this.$emit('chosenCategory', value)
    },

    submitedCategory(value) {
      this.$emit('submitedCategory', value)
    }

  }

}
</script>

<style>
</style>